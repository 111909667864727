define(['lodash',
    'santa-core-utils',
    'skins/util/skins'
], function (
    _,
    coreUtilsLib,
    skinsJson
) {
    'use strict';

    return {
        /***
         *
         * @param {string} skinName
         * @returns {object|undefined} Skin object by name, returns undefined if skin not found
         */
        get(skinName) {
	        const skin = skinsJson[skinName];

            if (!skin) {
	            if (_.isNil(skinName)) {
		            return;
	            }

	            /**
                 * svgshape skins are not loaded to the skinsJson
                 */
                if (!_.startsWith(skinName, 'svgshape.')) {
                    coreUtilsLib.log.error('Required skin wasn\'t loaded yet', skinName);
                }
            }

            return skin;
        },
        /***
         *
         * @param {object} skinsMap - map of skins to add to the map
         */
        addBatch(skinsMap) {
            _.assign(skinsJson, skinsMap);
        }
    };
});
